
$border-width: 1px;
$border-type: solid;
$border-radius: 4px;
$height-countdown-bar: 4px;

// SUCCESS
$color-light--success: #E3FFE4;
$color-dark--success: #18911C;
$color-border-success: #2BD230;
// INFO
$color-light--info: #E4F1FF;
$color-dark--info: #0d57a6;
$color-border-info: #003db0;
// WARNING
$color-light--warning: #FFE4CC;
$color-dark--warning: #FF5B00;
$color-border-warning: #DC6726;
// ERROR
$color-light--error: #F2D6D6;
$color-dark--error: #BD0000;
$color-border-error: #A20000;

.AlertsCard {
    margin-bottom: 10px;
    min-width: 300px;
    max-width: 300px;
    background-color: #fff;
    border-width: $border-width;
    border-style: $border-type;
    border-radius: $border-radius;
    transition: transform;
    transition-timing-function: ease-out;
    transition-property: transform height;

    &--TOP_LEFT,
    &--BOTTOM_LEFT,
    &--TOP_RIGHT,
    &--BOTTOM_RIGHT,
    &--TOP_CENTER,
    &--BOTTOM_CENTER {
        &-init {
            transform: translateX(0) translateY(0) !important;
        }
    }

    &--TOP_LEFT,
    &--BOTTOM_LEFT {
        transform: translateX(-1080px);
    }

    &--TOP_RIGHT,
    &--BOTTOM_RIGHT {
        transform: translateX(1080px);
    }

    &--TOP_CENTER {
        transform: translateY(-1080px);
    }

    &--BOTTOM_CENTER {
        transform: translateY(1080px);
    }

    &--SUCCESS {
        color: $color-dark--success;
        border-color: $color-border-success;
        background-color: $color-light--success;
    }

    &--INFO {
        color: $color-dark--info;
        border-color: $color-border-info;
        background-color: $color-light--info;
    }

    &--WARNING {
        color: $color-dark--warning;
        border-color: $color-border-warning;
        background-color: $color-light--warning;
    }

    &--ERROR {
        color: $color-dark--error;
        border-color: $color-border-error;
        background-color: $color-light--error;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom-width: $border-width;
        border-bottom-style: $border-type;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border-bottom-color: inherit;
        height: 30px;

        &-left,
        &-right {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 10px;
        }

        &-left {
            font-size: 16px;
        }

        &-right {
            font-family: Helvetica, Arial, sans-serif;

            &-time {
                display: flex;
                align-items: center;
                font-size: 11px;
                margin-right: 5px;
            }

            &-button {
                font-size: 16px;
                height: 100%;
                width: 30px;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    &__body {
        position: relative;
        padding: 8px 10px 6px;
        font-weight: bold;
        font-size: 13px;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;

        &-countdown {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: $height-countdown-bar;

            &-bar {
                width: 100%;
                height: 100%;
                animation-name: CountDown;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                transform-origin: left;
                transition-property: height;
                transition-duration: 150ms;
                transition-timing-function: ease-out;

                @keyframes CountDown {
                    from { transform: scaleX(1); }
                    to { transform: scaleX(0); }
                }

                &--pinned {
                    height: 0;
                }

                &--SUCCESS {
                    background-color: $color-dark--success;
                }

                &--INFO {
                    background-color: $color-dark--info;
                }

                &--WARNING {
                    background-color: $color-dark--warning;
                }

                &--ERROR {
                    background-color: $color-dark--error;
                }
            }
        }
    }
}
