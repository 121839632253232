
$passwordToggle-width: 36px;

.ModalForgotPasswordDialog {
    max-width: 800px;
}

.ModalForgotPassword {
    position: relative;
    text-align: center;
    padding: 60px 10px 40px;

    @include mobile() {
        padding: 40px 5px 10px;
    }

    &__title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 50px;

        @include mobile() {
            font-size: 20px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px 30px;

        &-label,
        &-inputGroup,
        &-PasswordStrengthMeter {
            max-width: 380px !important;
        }

        &-label {
            padding: 0 10px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            color: #a6a6a6;

            @include mobile() {
                font-size: 11px;
            }
        }

        &-hiddenEmailInput {
            visibility: hidden;
            width: 0;
            height: 0;
            opacity: 0;
        }

        &-inputGroup {
            &-input {
                padding-right: $passwordToggle-width !important;
                font-size: 17px !important;
                border: 0 none !important;
                border-bottom: 1px solid lightgray !important;
                height: 40px !important;
                background-color: #fff !important;

                @include mobile() {
                    font-size: 15px !important;
                }

                &:disabled,
                &:read-only {
                    background-color: #fff !important;
                }

                &:focus {
                    border-bottom-color: gray !important;
                }

                &--success {
                    padding: 0 10px !important;
                    border-bottom-color: #fff !important;
                    text-align: center !important;
                }
            }

            &-passwordToggle {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: $passwordToggle-width;
            }
        }

        &-PasswordStrengthMeter {
            border-radius: 0 !important;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-success {
            margin-top: 10px;
            color: #4BB543;
            font-size: 18px;
            font-weight: bold;

            @include mobile() {
                font-size: 16px;
            }
        }
    }

    &__button {
        &--backToRequestUI {
            margin-top: 15px !important;
            padding: 0 20px !important;
            min-height: 25px !important;
            font-size: 13px !important;
            color: #9f9f9f !important;

            @include mobile() {
                font-size: 11px !important;
            }
        }

        &--toLogin {
            display: flex !important;
            height: 50px !important;
            font-size: 15px !important;
            font-weight: bold !important;
            margin-top: 10px !important;
            color: #555 !important;

            &:hover {
                color: #000 !important;
            }
        }

        &--confirm {
            height: 60px !important;
            width: 100% !important;
            max-width: 300px !important;
            border-radius: 4px !important;
            background-color: #fff !important;
            font-size: 21px !important;
            font-weight: bold !important;
            border: 0 none !important;
            transition-property: box-shadow, transform !important;
            transition-duration: 100ms !important;
            transition-timing-function: ease-in-out !important;

            &:active {
                box-shadow: inset 0 1px 3px rgba(#000, 0.4), inset 0 1px 2px rgba(#000, 0.5) !important;
            }
        }
    }
}
