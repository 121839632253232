@import './_variables.scss';

$border-color: #eee;

.ItemPickerView {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: 1px solid $border-color;
    background-color: #fbfbfb;

    &--isSingleSelection {
        background-color: #fff;
    }

    &__items {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 49px;
        max-height: 112px;
        background-repeat: no-repeat;
        background-position: center right 6px;
        background-size: 15px;
        cursor: pointer;

        &--isValid,
        &--isInvalid {
            padding-right: 28px;
            background-repeat: no-repeat;
            background-position: center right 6px;
            background-size: 15px;
        }

        &--isValid {
            background-image: $background-image-valid;
        }

        &--isInvalid {
            background-image: $background-image-invalid;
        }

        &--isSingleSelection {
            min-height: 100%;
            max-height: none;
        }

        &--isInput {
            cursor: text;
        }

        &-wrapper {
            flex: inherit;
            display: inherit;
            flex-wrap: inherit;
            align-items: inherit;
            overflow: hidden;
            padding: 5px;
            margin-right: 5px;

            &--isSingleSelection {
                padding: 0;
            }

            &-placeholder {
                margin-left: 10px;
                color: $placeholder-color;
                font-size: 15px;
            }

            &-processing {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
            }
        }
    }

    &__dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        background-color: #fff;
        min-width: 40px;
        width: 40px;
        font-size: 24px;
        border-left: 1px solid $border-color;
    }

    &__ItemPicker {
        position: fixed;
        margin-top: 3px;
        width: 100%;
        z-index: 1;
    }
}
