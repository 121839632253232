
$margin: 15px;

.Alerts {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
    z-index: 999999;

    &--TOP_CENTER,
    &--TOP_LEFT,
    &--TOP_RIGHT {
        top: $margin;
    }

    &--BOTTOM_CENTER,
    &--BOTTOM_LEFT,
    &--BOTTOM_RIGHT {
        justify-content: flex-end;
        bottom: $margin;
    }

    &--TOP_LEFT,
    &--BOTTOM_LEFT {
        left: $margin;
        align-items: flex-start;
    }

    &--TOP_RIGHT,
    &--BOTTOM_RIGHT {
        right: $margin;
        align-items: flex-end;
    }

    &--TOP_CENTER {
        align-items: center;
    }

    &--BOTTOM_CENTER {
        align-items: center;
    }
}
