
$color: #000;

.ItemPickerItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #373737;
    padding: 5px 10px;
    border-bottom: 1px dashed #acacac;
    user-select: none;
    cursor: pointer;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #fff;
    transition-property: background-color, border-left-color;
    transition-timing-function: ease-out;
    transition-duration: 120ms;

    &:hover {
        border-left-color: rgba($color, 0.05);
        background-color: rgba($color, 0.04);
    }

    &:active {
        border-left-color: rgba($color, 0.1);
        background-color: rgba($color, 0.06);
    }

    &--active {
        border-left-color: rgba($color, 0.15) !important;
        background-color: rgba($color, 0.08) !important;
    }

    &:last-child {
        border-bottom: 0 none;
    }
}
