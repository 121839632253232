.Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    user-select: none;
    transition-timing-function: ease-out;
    transition-duration: 300ms;
    transition-property: background-color, color, opacity, border-color, box-shadow;

    &--disabled,
    &--processing {
        pointer-events: none;
    }

    &--disabled {
        opacity: 0.5;
    }

    &--processing {
        opacity: 0.6;
    }
}
