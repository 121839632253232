.Input {
    display: inline-flex;
    border: 0 none;
    padding: 0 10px;
    flex: 1 auto;
    min-width: 0;
    background-color: #fff;
    transition-property: border-color, color, background-color;
    transition-duration: 180ms;
    transition-timing-function: ease-out;
    // mainly to remove firefox autofill coloring
    filter: none;

    &::placeholder {
        color: $placeholder-color;
    }

    &:invalid,
    &:focus {
        box-shadow: none;
        outline: none;
        appearance: none;
    }

    &--isValid,
    &--isInvalid {
        padding-right: 30px;
        background-repeat: no-repeat;
        background-position: center right 6px;
        background-size: 15px;
    }

    &--isValid {
        background-image: $background-image-valid;
    }

    &--isInvalid {
        background-image: $background-image-invalid;
    }

    &--textarea {
        resize: vertical;
        background-position: top 6px right 6px;
    }
}
