
.PasswordStrengthMeter {
    width: 100%;
    height: 10px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: inset 0 0 3px rgba(#000, 0.15);
    background-color: rgba(0, 0, 0, 0.1);

    &-bar {
        height: 100%;
        width: 100%;
        overflow: inherit;
        transform-origin: left;
        transition-duration: 250ms;
        transition-property: transform, background-color;
        transition-timing-function: ease-in-out;
        transform: scaleX(0);

        &--1 {
            background-color: red;
            transform: scaleX(0.25);
        }

        &--2 {
            background-color: yellow;
            transform: scaleX(0.5);
        }

        &--3 {
            background-color: orange;
            transform: scaleX(0.75);
        }

        &--4 {
            background-color: green;
            transform: scaleX(1);
        }
    }
}
