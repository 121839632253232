
.ItemPickerSearch {
    width: 100%;
    border: 0 none;
    border-bottom: 1px solid rgba(#000, 0.2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 10px;
    height: 34px;
    font-size: 12px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $placeholder-color;
    }
}
