
.ItemPicker {
    z-index: 1;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.1);
    box-shadow: 0 6px 10px rgba(#000, 0.1);
    background-color: #fff;
    overflow: hidden;

    &__items {
        max-height: 200px;
        overflow-y: auto;

        &-empty,
        &-processing {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: gray;
        height: 35px;
        font-size: 14px;
    }
}
