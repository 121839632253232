
.ItemPickerViewItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: $ItemPickerViewItem-height;
    margin: $ItemPickerViewItem-margin;
    max-width: 100%;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(#000, 0.1);

    &:active {
        box-shadow: none;
    }

    &--isSingleSelection {
        background-color: inherit;
        box-shadow: none;
    }

    &__content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
