
.ItemPickerViewInput {
    position: relative;
    display: inline-flex;
    flex: 1;
    align-items: center;
    height: $ItemPickerViewItem-height;
    margin: $ItemPickerViewItem-margin;

    &__input {
        display: block;
        min-width: 80px;
        width: 100%;
        border: 0 none;
        padding: 0;
        font-size: 15px;
        background-color: transparent;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $placeholder-color;
        }
    }

    &__match {
        position: fixed;
        z-index: 1;
    }
}
